import * as React from 'react';
const SvgHome = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 370" />
        <path
            d="M12.471 3.135a.889.889 0 0 0-.942 0L5.253 7.057A2.667 2.667 0 0 0 4 9.319v7.015A2.667 2.667 0 0 0 6.667 19h1.777a.889.889 0 0 0 .889-.889v-4.444a.889.889 0 0 1 .889-.889h3.556a.889.889 0 0 1 .889.889v4.444a.889.889 0 0 0 .889.889h1.778A2.667 2.667 0 0 0 20 16.333V9.319a2.667 2.667 0 0 0-1.253-2.261Z"
            data-name="Path 383"
        />
    </svg>
);
export default SvgHome;
