/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Thumbor from '@common_image';
import getPath from '@helper_getpath';
import { setResolver, getResolver } from '@helper_localstorage';
import { features } from '@config';
import Link from 'next/link';
import ContentBoundary from '@commons/ContentBoundary';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_100, GRAY_200, GRAY_800 } from '@src_theme/colors';
import HomeIcon from '@src_theme/icons/Home';
import { sortAlphabetically } from '@root/src/helpers/sortAlphabetically';

const useStyles = makeStyles({
    root: {
        backgroundColor: GRAY_100,
        boxShadow: `0px 1px 0px ${GRAY_200}`,
        '& .menuitem-link': {
            fontSize: 10,
            padding: '0 12px !important',
            '@media (min-width: 992px)': {
                fontSize: 12,
            },
            '@media (min-width: 1200px)': {
                fontSize: 14,
                padding: '0 16px !important',
            },
        },
        '& .nav li a': {
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
        },
    },
});

const generateLevel2 = (data, handleClick, generateLink, hasSubmenu) => {
    const [active, setActive] = React.useState(0);
    const sortData = sortAlphabetically(data);
    const child = sortData[active];
    const image_path = child.image_path || child.dropdown_bgimage;

    return (
        <>
            <div className={['nav-column nav-column-left', `${hasSubmenu ? 'col-sm-2' : 'col-sm-12'}`].join(' ')}>
                {sortData.map((val, index) => (
                    <Link key={index} href={generateLink(val)[0]} as={generateLink(val)[1]} prefetch={false}>
                        <a
                            onClick={() => handleClick(val)}
                            className={active === index ? 'active gtm_mitra10_cta_category1' : 'gtm_mitra10_cta_category1'}
                            onMouseEnter={() => setActive(index)}
                        >
                            {val.name}
                        </a>
                    </Link>
                ))}
            </div>
            {child.children.length !== 0 && (
                <div className="nav-column nav-column-right col-sm-10 row">
                    <div className={`${image_path ? 'col-sm-9' : 'col-sm-12'} row`}>
                        {sortAlphabetically(child.children).map((lvl3, id3) => (
                            <div className={image_path ? 'col-sm-4' : 'col-sm-3'} key={id3}>
                                <Link href={generateLink(lvl3)[0]} as={generateLink(lvl3)[1]} prefetch={false}>
                                    <a className="link-parent gtm_mitra10_cta_category2" onClick={() => handleClick(lvl3)}>
                                        {lvl3.name}
                                    </a>
                                </Link>
                                <ul className="list-item__menu">
                                    {sortAlphabetically(lvl3.children).map(
                                        (lvl4, id4) => generateLink(lvl4)[1] !== '/undefined' && (
                                            <li key={id4}>
                                                <Link href={generateLink(lvl4)[0]} as={generateLink(lvl4)[1]} prefetch={false}>
                                                    <a className="link-child gtm_mitra10_cta_category3" onClick={() => handleClick(lvl4)}>
                                                        {lvl4.name}
                                                    </a>
                                                </Link>
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        ))}
                    </div>
                    {image_path ? (
                        <div className="col-sm-3">
                            <Thumbor
                                // eslint-disable-next-line no-nested-ternary
                                src={image_path}
                                className="img_cat"
                                width={500}
                                height={800}
                                quality={80}
                                alt={child.name}
                            />
                        </div>
                    ) : null}
                </div>
            )}

            <style jsx>
                {`
                    .row {
                        margin: 0;
                    }
                    .nav-column-left {
                        background: #f3f3f3;
                        padding: 15px 10px;
                        text-transform: uppercase;
                    }
                    .nav-column-right {
                        padding: 15px 0;
                    }
                    .list-item__menu a {
                        font-weight: normal;
                    }
                    .link-parent {
                        line-height: 1.5;
                        padding-bottom: 4px;
                        text-transform: uppercase;
                    }
                    .link-child {
                        line-height: 1.5;
                        font-weight: 500;
                        letter-spacing: 0.7px;
                    }
                `}
            </style>
        </>
    );
};

const Menu = (props) => {
    const { data, storeConfig } = props;
    const styles = useStyles();
    let hasSubmenu = false;
    const cmsPages = storeConfig && storeConfig.cms_page ? storeConfig.cms_page.split(',') : [];
    const menu = features.vesMenu.enabled ? data.vesMenu.items : data.categoryList[0].children;
    const generateLink = (cat) => {
        const link = cat.link ? getPath(cat.link) : `/${cat.url_path}`;
        if (features.vesMenu.enabled) {
            if (cat.link_type === 'category_link') {
                return ['/[...slug]', link];
            }
            const cms = cmsPages.find((cmsPage) => cmsPage === link.replace('/', ''));
            if (cms) {
                return ['/[...slug]', link];
            }
            return [link, link];
        }
        return ['/[...slug]', link];
    };
    const handleClick = async (cat) => {
        const link = cat.link ? getPath(cat.link) : `/${cat.url_path}`;
        const urlResolver = getResolver();
        if (features.vesMenu.enabled) {
            if (cat.link_type === 'category_link') {
                urlResolver[link] = {
                    type: 'CATEGORY',
                    id: cat.category_id,
                };
                await setResolver(urlResolver);
            } else {
                const cms = cmsPages.find((cmsPage) => cmsPage === link.replace('/', ''));
                if (cms) {
                    urlResolver[link] = {
                        type: 'CMS_PAGE',
                    };
                    await setResolver(urlResolver);
                }
            }
        } else {
            urlResolver[link] = {
                type: 'CATEGORY',
                id: cat.id,
            };
            await setResolver(urlResolver);
        }
    };

    return (
        <nav className={styles.root}>
            <ContentBoundary>
                <ul className="nav" role="menubar">
                    {menu.map((val, idx) => {
                        if ((val.include_in_menu || features.vesMenu.enabled) && val.name) {
                            let count = 0;
                            val.children.forEach((item) => {
                                if (item.children.length) {
                                    count += 1;
                                }
                            });
                            if (count) {
                                hasSubmenu = true;
                            } else {
                                hasSubmenu = false;
                            }
                            return (
                                <li key={idx} role="menuitem">
                                    {val.link ? (
                                        <Link href={generateLink(val)[0]} as={generateLink(val)[1]} prefetch={false}>
                                            <a onClick={() => handleClick(val)} className="menuitem-link">
                                                {idx === 0 && <HomeIcon width={24} />}
                                                {val.name}
                                            </a>
                                        </Link>
                                    ) : (
                                        <a href="#" className="menuitem-link" dangerouslySetInnerHTML={{ __html: val.name }} />
                                    )}

                                    {val.children.length > 0 ? (
                                        <div
                                            className="mega-menu row"
                                            role="menu"
                                            style={{
                                                width: hasSubmenu ? '100%' : 'fit-content',
                                                left: hasSubmenu ? 0 : 'unset',
                                            }}
                                        >
                                            {generateLevel2(val.children, handleClick, generateLink, hasSubmenu)}
                                        </div>
                                    ) : null}
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
            </ContentBoundary>
            <style jsx global>
                {`
                    /* mini reset */
                    .nav {
                        width: 100%;
                    }
                    .nav,
                    .nav a,
                    .nav form,
                    .nav input,
                    .nav li,
                    .nav ul {
                        border: none;
                        margin: 0;
                        padding: 0;
                        /* height: 24px; */
                    }
                    .nav a {
                        text-decoration: none;
                    }
                    .nav li {
                        list-style: none;
                    }

                    /* menu container */
                    .nav {
                        cursor: default;
                        display: flex;
                        align-items: center;
                        position: relative;
                        z-index: 500;
                    }
                    ul.nav {
                        height: 32px;
                    }

                    .nav li:first-child a {
                        padding-left: 0 !important;
                    }

                    .menu-wrapper,
                    .nav,
                    .nav > li,
                    .nav > li > a {
                        height: 100%;
                    }

                    /* menu links */
                    @media (max-width: 900px) {
                        .nav > li > a {
                            font-size: 8px;
                            padding: 0 8px !important;
                        }
                    }
                    .nav > li > a {
                        background: #f4f5f5;
                        font-size: 12px;
                        color: ${GRAY_800};
                        display: block;
                        padding: 0 1.25em;
                        transition: all 0.3s ease;
                        z-index: 510;
                        position: relative;
                        text-transform: capitalize;
                    }
                    .nav > li > a:focus,
                    .nav > li:hover > a {
                        text-decoration: underline;
                        text-underline-position: under;
                    }
                    .nav > li:first-child > a {
                        border-left: none;
                        border-radius: 3px 0 0 3px;
                        padding-left: 0;
                    }

                    /* search form */
                    .nav-search > form {
                        border-left: 1px solid #4b4441;
                        height: 3.5em;
                        position: relative;
                        width: inherit;
                        z-index: 510;
                    }
                    .nav-search input[type='text'] {
                        background: #372f2b;
                        color: #999;
                        display: block;
                        float: left;
                        font-weight: bold;
                        line-height: 1.5;
                        padding: 1em 0;
                        text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
                        transition: all 0.3s ease 1s;
                        width: 0;
                    }
                    .nav-search input[type='text']:focus {
                        color: #fcfcfc;
                    }
                    .nav-search input[type='text']:focus,
                    .nav-search:hover input[type='text'] {
                        padding: 1em 1.25em;
                        transition: all 0.3s ease 0.1s;
                        width: 6.875em;
                    }
                    .nav-search input[type='submit'] {
                        background: #372f2b url(../img/search-icon.png) no-repeat center center; /* IE8 fallback */
                        background: #372f2b url(../img/search-icon.svg) no-repeat center center;
                        border-radius: 0 3px 3px 0;
                        cursor: pointer;
                        display: block;
                        float: left;
                        height: 3.5em;
                        padding: 0 1.25em;
                        transition: all 0.3s ease;
                    }
                    .nav-search input:focus,
                    .nav-search input[type='submit']:hover {
                        background-color: #4b4441;
                    }

                    /* menu dropdown */
                    .mega-menu {
                        background: #fff;
                        border: 1px solid #ddd;
                        border-radius: 0 0 3px 3px;
                        opacity: 0;
                        position: absolute;
                        transition: all 0.3s ease 0.15s;
                        display: none;
                        margin: 0;
                        min-height: fit-content;
                        font-size: 12px;
                    }
                    @media (min-width: 1024px) {
                        .mega-menu {
                            font-size: 14px;
                        }
                    }
                    li:hover > .mega-menu {
                        opacity: 1;
                        overflow: visible;
                        display: flex;
                    }

                    /* menu content */
                    .nav-column a {
                        color: #888;
                        display: block;
                        font-weight: bold;
                        line-height: 2;
                    }
                    .nav-column a:hover {
                        color: #2e3092 !important;
                    }

                    .nav-column .active {
                        color: #2e3092 !important;
                    }
                    .nav-column h3 {
                        color: #372f2b;
                        font-size: 0.95em;
                        font-weight: bold;
                        line-height: 1.15;
                        margin: 1.25em 0 0.75em;
                        text-transform: uppercase;
                    }
                    .cat-label-v2 {
                        top: -6px;
                        position: absolute;
                        background: red;
                        z-index: 99;
                        left: 10px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                `}
            </style>
        </nav>
    );
};

export default Menu;
